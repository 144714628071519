import { Form, Formik } from "formik";
import { useMutation } from "@apollo/client";
import { useMemo } from "react";
import styled from "@xstyled/styled-components";

import {
  Button,
  Card,
  FieldWrapper,
  Input,
  Text,
  SelectField,
  Spacing,
  Tipbox,
} from "@otta/design";
import {
  BrandAssetGroup,
  CompanyBrandAssetLinkInput,
  CompanyBrandAssetsQuery,
  CreateCompanyBrandAssetLinkDocument,
  DeleteCompanyBrandAssetLinkDocument,
  UpdateCompanyBrandAssetLinkDocument,
} from "@toolbox/schema";

type Company = NonNullable<CompanyBrandAssetsQuery["company"]>;
type Asset = Company["brandAssets"][0];
type Link = Asset["links"][0];

const Row = styled.div`
  display: flex;
  gap: 1rem;

  & > * {
    flex: 1 1 33%;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 1rem;
`;

function Select({
  options,
  onChange,
  value,
}: {
  value?: string;
  options: { label: string; value: string }[];
  onChange(set: string | null): void;
}): React.ReactElement {
  const val = useMemo(
    () => options.find(({ value: v }) => v === value),
    [options, value]
  );

  return (
    <SelectField
      isClearable
      options={options}
      onChange={v => {
        v?.value ? onChange(v.value) : onChange(null);
      }}
      value={val}
    />
  );
}

export function LinkForm({
  placements,
  regions,
  company,
  parent,
}: {
  company: Company;
  parent: Asset | Link;
  regions: { id: string; name: string }[];
  placements: { id: string; name: string }[];
}): React.ReactElement {
  const updating = parent.__typename === "CompanyBrandAssetLink";

  const [run, { error }] = useMutation(
    updating
      ? UpdateCompanyBrandAssetLinkDocument
      : CreateCompanyBrandAssetLinkDocument
  );

  const placementOptions = useMemo(
    () =>
      placements
        .map(({ id, name }) => ({ label: name, value: id }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [placements]
  );

  const groupOptions = useMemo(
    () => Object.values(BrandAssetGroup).map(v => ({ label: v, value: v })),
    []
  );

  const officeOptions = useMemo(
    () =>
      (company.offices ?? []).flatMap(t =>
        t ? [{ label: t.address, value: t.id }] : []
      ),
    [company]
  );

  const regionOptions = useMemo(
    () => regions.map(({ id, name }) => ({ label: name, value: id })),
    [regions]
  );

  const teamOptions = useMemo(
    () => company.teams.map(t => ({ label: t.name, value: t.id })),
    [company]
  );

  const teamMemberOptions = useMemo(
    () =>
      company.teams.flatMap(team => {
        return team.members.map(member => ({
          label: `${team.name} - ${member.name ?? member.jobTitle}`,
          value: member.id,
        }));
      }),
    [company]
  );

  const jobOptions = useMemo(
    () => company.listJobs.map(t => ({ label: t.title, value: t.id })),
    [company]
  );

  const [runDelete, { loading: deleteLoading }] = useMutation(
    DeleteCompanyBrandAssetLinkDocument
  );

  const input: CompanyBrandAssetLinkInput = useMemo(() => {
    if (updating) {
      return {
        jobId: parent.job?.id,
        options: parent.options,
        priority: parent.priority,
        brandAssetGroupName: parent.brandAssetGroup?.name,
        desktopPlacementId: parent.desktopPlacement?.placementId,
        mobilePlacementId: parent.mobilePlacement?.placementId,
        companyOfficeId: parent.companyOffice?.id,
        companyTeamId: parent.companyTeam?.id,
        companyTeamMemberId: parent.companyTeamMember?.id,
        regionId: parent.region?.id,
      };
    } else {
      return {};
    }
  }, [parent, updating]);

  return (
    <Card>
      <Spacing size={1}>
        <Text bold size={1}>
          {updating ? `Link #${parent.id}` : "Add link"}
        </Text>
        {!!error && <Tipbox level="error">{error.message}</Tipbox>}
        <Formik<CompanyBrandAssetLinkInput>
          onSubmit={input => run({ variables: { id: parent.id, input } })}
          initialValues={input}
        >
          {({ values, handleChange, isSubmitting, setFieldValue }) => (
            <Form>
              <Spacing>
                <FieldWrapper label="Group name">
                  {() => (
                    <Select
                      options={groupOptions}
                      value={values.brandAssetGroupName ?? undefined}
                      onChange={v => setFieldValue("brandAssetGroupName", v)}
                    />
                  )}
                </FieldWrapper>
                <Row>
                  <FieldWrapper label="Team">
                    {() => (
                      <Select
                        options={teamOptions}
                        value={values.companyTeamId ?? undefined}
                        onChange={v => setFieldValue("companyTeamId", v)}
                      />
                    )}
                  </FieldWrapper>
                  <FieldWrapper label="Team member">
                    {() => (
                      <Select
                        options={teamMemberOptions}
                        value={values.companyTeamMemberId ?? undefined}
                        onChange={v => setFieldValue("companyTeamMemberId", v)}
                      />
                    )}
                  </FieldWrapper>
                  <FieldWrapper label="Job">
                    {() => (
                      <Select
                        options={jobOptions}
                        value={values.jobId ?? undefined}
                        onChange={v => setFieldValue("jobId", v)}
                      />
                    )}
                  </FieldWrapper>
                  <FieldWrapper label="Region">
                    {() => (
                      <Select
                        options={regionOptions}
                        value={values.regionId ?? undefined}
                        onChange={v => setFieldValue("regionId", v)}
                      />
                    )}
                  </FieldWrapper>
                </Row>
                <Row>
                  <FieldWrapper label="Office">
                    {() => (
                      <Select
                        options={officeOptions}
                        value={values.companyOfficeId ?? undefined}
                        onChange={v => setFieldValue("companyOfficeId", v)}
                      />
                    )}
                  </FieldWrapper>
                </Row>
                <Row>
                  <FieldWrapper label="Mobile">
                    {() => (
                      <Select
                        options={placementOptions}
                        value={values.mobilePlacementId ?? undefined}
                        onChange={v => setFieldValue("mobilePlacementId", v)}
                      />
                    )}
                  </FieldWrapper>
                  <FieldWrapper label="Desktop">
                    {() => (
                      <Select
                        options={placementOptions}
                        value={values.desktopPlacementId ?? undefined}
                        onChange={v => setFieldValue("desktopPlacementId", v)}
                      />
                    )}
                  </FieldWrapper>
                  <FieldWrapper label="Priority" required>
                    {() => (
                      <Input
                        type="number"
                        name="priority"
                        value={values.priority ?? undefined}
                        onChange={handleChange}
                      />
                    )}
                  </FieldWrapper>
                </Row>
                <ButtonRow>
                  <Button level="primary" type="submit" disabled={isSubmitting}>
                    {updating ? "Save changes" : "Add link"}
                  </Button>
                  {updating && (
                    <Button
                      level="destructive"
                      disabled={deleteLoading}
                      onClick={() =>
                        runDelete({ variables: { id: parent.id } })
                      }
                    >
                      Delete
                    </Button>
                  )}
                </ButtonRow>
              </Spacing>
            </Form>
          )}
        </Formik>
      </Spacing>
    </Card>
  );
}
