import { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import styled, { css, up } from "@xstyled/styled-components";

import { boxShadow, palette } from "@otta/design-tokens";
import { AdminsOnly } from "@toolbox/components/AdminsOnly";
import { Permission } from "@toolbox/schema";
import { CurrentRoleType, useUser } from "@toolbox/utils/user";
import { Icon } from "@otta/icons";

const Nav = styled.nav<{ open?: boolean }>`
  background: ${({ open }) =>
    open ? palette.brand.yellow : palette.brand.white};
  width: 100%;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  padding: 0 md;
  box-shadow: ${boxShadow};

  ${up(
    "tablet",
    css`
      background: ${palette.brand.white};
      padding: 0 md;
    `
  )}
`;

const NavLinks = styled.ul<{ open?: boolean }>`
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  right: 100%;
  bottom: 0;
  overflow-y: auto;
  background: ${palette.brand.yellow};
  height: 100vh;
  width: 100vw;
  text-align: left;
  transition: default;
  transition-property: right;
  will-change: right;
  transform: translateY(100%);
  z-index: 1;
  ${({ open }) => open && "right: 0; left: 0; padding: 16px 0 0;"}

  ${up(
    "tablet",
    css`
      display: flex;
      flex-direction: row;
      flex: 1 1 0;
      justify-content: flex-start;
      align-items: flex-end;
      position: static;
      transform: unset;
      transition: unset;
      height: auto;
      background: transparent;
      margin: 0;
      padding: 0;
    `
  )};
`;

const LogoutWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding-top: md;
  padding-right: xl;
  height: auto;
`;

const IconWrapper = styled.div`
  display: flex;
  gap: xs;
`;

const HamburgerItem = styled.span<{ active?: boolean }>`
  display: block;
  height: 5px;
  background-color: black;
  width: 100%;
  transition: default;
  transition-property: opacity, transform;
  border-radius: 2px;
  position: absolute;
  top: 10px;
  opacity: ${({ active }) => (active ? 0 : 1)};

  &:first-of-type {
    opacity: 1;
    position: absolute;
    top: 0px;
    transform: ${({ active }) =>
      active ? "rotate(45deg) translate(25%, 125%)" : "rotate(0)"};
  }

  &:last-of-type {
    opacity: 1;
    position: absolute;
    top: 20px;
    transform: ${({ active }) =>
      active ? "rotate(-45deg) translate(25%, -125%)" : "rotate(0)"};
  }
`;

const Hamburger = styled.div<{ active?: boolean }>`
  width: 30px;
  height: 30px;
  position: relative;
  top: 16px;
  cursor: pointer;
  margin: 0;
  ${up(
    "tablet",
    css`
      display: none;
    `
  )}
`;

const DesktopOnly = styled.div`
  display: none;

  ${up(
    "tablet",
    css`
      display: block;
    `
  )}
`;

const Link = styled.li`
  display: flex;
  margin: md;

  ${up(
    "tablet",
    css`
      margin: 0 md;
    `
  )}
`;

const NavLink = styled(RouterNavLink)<{ color?: string }>`
  color: ${({ color }) => color ?? palette.brand.black};
  text-decoration: none;
  font-weight: 600;
  border-bottom: 5px solid transparent;
  padding-bottom: 6;
  &:hover {
    border-color: gray-100;
  }
  &.active {
    border-color: yellow-500 !important;
  }
`;

export function NavBar(): React.ReactElement {
  const { role, permissions } = useUser();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const handleClick = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  const canViewCompaniesPage =
    role === CurrentRoleType.Admin ||
    role === CurrentRoleType.ExternalResearcher ||
    role === CurrentRoleType.InternalOperator;
  const canViewJobsPage =
    role === CurrentRoleType.Admin || role === CurrentRoleType.InternalOperator;
  const canViewSalariesPage =
    role === CurrentRoleType.Admin ||
    permissions?.includes(Permission.ManageJobSalaries);
  const canViewEndorsementsPage =
    role === CurrentRoleType.Admin ||
    permissions?.includes(Permission.ManageCompanyEndorsements);

  return (
    <header>
      <Nav open={hamburgerOpen}>
        <Hamburger onClick={handleClick}>
          <HamburgerItem active={hamburgerOpen} />
          <HamburgerItem active={hamburgerOpen} />
          <HamburgerItem active={hamburgerOpen} />
        </Hamburger>
        <NavLinks
          open={hamburgerOpen}
          onClick={() => hamburgerOpen && handleClick()}
        >
          {canViewCompaniesPage && (
            <DesktopOnly>
              <Link>
                <NavLink to="/companies">Companies</NavLink>
              </Link>
            </DesktopOnly>
          )}
          {canViewJobsPage && (
            <DesktopOnly>
              <Link>
                <NavLink to="/jobs">Jobs</NavLink>
              </Link>
            </DesktopOnly>
          )}
          <AdminsOnly>
            <Link>
              <NavLink to="/users">Users</NavLink>
            </Link>
          </AdminsOnly>
          {canViewSalariesPage && (
            <DesktopOnly>
              <Link>
                <NavLink to="/salaries">Salaries</NavLink>
              </Link>
            </DesktopOnly>
          )}
          {canViewEndorsementsPage && (
            <DesktopOnly>
              <Link>
                <NavLink to="/endorsements">Endorsements</NavLink>
              </Link>
            </DesktopOnly>
          )}
          <AdminsOnly>
            <Link>
              <NavLink to="/misc">Misc</NavLink>
            </Link>
          </AdminsOnly>
          <AdminsOnly>
            <Link>
              <NavLink to="/scraper">Scraper</NavLink>
            </Link>
          </AdminsOnly>
          <AdminsOnly>
            <Link>
              <NavLink to="/starter">Starter</NavLink>
            </Link>
          </AdminsOnly>
          <AdminsOnly>
            <Link>
              <NavLink
                as="a"
                href="https://metabase.otta.com/dashboard/1-metrics"
              >
                <IconWrapper>
                  Statistics
                  <Icon icon="external" size={1} />
                </IconWrapper>
              </NavLink>
            </Link>
          </AdminsOnly>
        </NavLinks>
        <LogoutWrapper>
          <Link>
            <NavLink color={palette.extended.red.shade600} to="/logout">
              Log out
            </NavLink>
          </Link>
        </LogoutWrapper>
      </Nav>
    </header>
  );
}
